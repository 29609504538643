<script setup lang="ts">
import { usePosQuery } from "~/queries/pos";
import { useRetailChains } from "~/queries/retail-chains";

const { data: availablePos, suspense: suspensePosQuery } = usePosQuery();
const { data: retailChains, suspense: suspenseRetailChains } =
  useRetailChains();
const userStore = useUserStore();
const { setIsBkoManager, setValues } = useActiveRetailChainAndPosStore();

setIsBkoManager(userStore.isBkoManager);

const { isBkoManager, retail_chain, pos, getRetailChainName, getPosCode } =
  storeToRefs(useActiveRetailChainAndPosStore());

const isModalOpen = ref(false);
function handleCloseModal() {
  isModalOpen.value = false;
}

onMounted(async () => {
  if (isBkoManager.value) {
    isModalOpen.value = false;
    return;
  }
  if (!retail_chain.value?.name || !pos.value?.code) {
    await suspenseRetailChains();
    await suspensePosQuery();

    if (isSingleRetailChain() && isSinglePos()) {
      isModalOpen.value = false;
      return setSingleValuesOnStore();
    }

    isModalOpen.value = true;
  }
});

const setSingleValuesOnStore = () => {
  setValues(retailChains.value[0], availablePos.value[0]);
};

const isSingleRetailChain = (): boolean => retailChains.value?.length === 1;
const isSinglePos = (): boolean => availablePos.value?.length === 1;

function onError(e: any) {
  console.error(e);
}
</script>

<template>
  <ClientOnly>
    <NuxtErrorBoundary @error="onError">
      <div>
        <VRetailChainPosModal
          v-if="isModalOpen"
          :is-modal-open="isModalOpen"
          @close="handleCloseModal"
        />

        <div
          class="flex flex-row gap-2 md:gap-4 p-2 font-semibold font-['Red Hat Text'] cursor-pointer hover:bg-zinc-200 rounded-full transition-all duration-200"
          @click="isModalOpen = true"
        >
          <div
            class="flex flex-col items-center justify-center text-xs md:text-sm"
          >
            <div class="flex flex-row justify-center items-center gap-1">
              <span class="hidden md:flex text-basicText font-['Red Hat Text']"
                >Rede:
              </span>
              <span class="text-basicText font-['Red Hat Text']">
                {{ getRetailChainName }}
              </span>
            </div>
          </div>

          <div class="w-[1px] bg-[#C2C2C2]" />

          <div class="flex flex-col items-center text-xs md:text-sm">
            <div class="flex flex-row justify-center items-center gap-1">
              <span class="hidden md:flex text-basicText font-['Red Hat Text']"
                >PDV:
              </span>
              <span class="text-basicText font-['Red Hat Text']">
                {{ getPosCode }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </NuxtErrorBoundary>
  </ClientOnly>
</template>
