<template>
  <div class="bg-[#242F26] w-full md:px-6">
    <div class="container mx-auto flex flex-col py-12">
      <div class="flex flex-col gap-8 md:gap-0 md:flex-row px-2">
        <div class="flex flex-col w-full gap-4">
          <div class="flex flex-row">
            <img
              src="/assets/images/logo-vemqda.png"
              class="w-[200px]"
              alt=""
            >
          </div>
          <div class="flex flex-row justify-between max-w-[200px]">
            <div class="flex flex-col">
              <UButton
                icon="mdi-instagram"
                size="lg"
                :ui="{
                  rounded: 'rounded-full',
                }"
                style="background-color: #475149"
                padded
              />
            </div>
            <div class="flex flex-col">
              <UButton
                icon="mdi-linkedin"
                size="lg"
                :ui="{
                  rounded: 'rounded-full',
                }"
                style="background-color: #475149"
              />
            </div>
            <div class="flex flex-col">
              <UButton
                icon="mdi-facebook"
                size="lg"
                :ui="{ rounded: 'rounded-full' }"
                style="background-color: #475149"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full text-white font-bold">
          <div class="flex flex-col md:flex-row gap-8 md:gap-12">
            <NuxtLink to="/vemqda/cadastro">
              <div class="flex flex-col">
                <a href="">Torne-se um afiliado e maximize seus ganhos</a>
              </div>
            </NuxtLink>
            <NuxtLink to="/vemqda/faq">
              <div class="flex flex-col">
                <a href="">FAQ</a>
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row py-4 gap-12 px-2">
        <div class="flex flex-col text-lg text-white font-semibold gap-2">
          <div class="flex flex-row gap-2 items-center">
            <UIcon name="i-heroicons-chat-bubble-oval-left" size="24" />
            <span>Contato</span>
          </div>
          <div class="flex flex-row">
            <span>(21) 96638-7507</span>
          </div>
          <div class="flex flex-row">
            <span>contato@vemqda.com.br</span>
          </div>
        </div>
      </div>

      <div
        class="flex md:flex-row flex-col font-semibold text-white justify-between w-full gap-8"
      >
        <div class="flex flex-row">
          <p>
            Veja nossos
            <a href="" class="text-[#7BA0FF] underline cursor-pointer"
              >Termos de Uso,</a
            >
            <a href="" class="text-[#7BA0FF] underline cursor-pointer"
              >politícas de privacidade e cookies</a
            >
          </p>
        </div>
        <div class="flex flex-col">
          <img src="/assets/images/logo-vertex.png" class="max-w-32" alt="" >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style></style>
