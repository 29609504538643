<template>
  <div class="flex flex-col container mx-auto py-5 md:px-6">
    <div class="flex flex-row text-xl md:text-[32px] text-[#304168] py-4 px-2">
      Marcas Parceiras
    </div>
    <div
      class="flex flex-row items-center w-full pl-2 py-4 gap-16 md:px-6 justify-center max-w-lg md:max-w-full overflow-x-auto"
    >
      <img src="/assets/images/fla-chip.png" class="max-w-20" alt="" >
      <img src="/assets/images/claro.png" class="max-w-20" alt="" >
      <img src="/assets/images/tim.png" class="max-w-20" alt="" >
      <img src="/assets/images/oi.png" class="max-w-20" alt="" >
    </div>

    <div id="afiliados" class="flex flex-col md:flex-row py-24 w-full gap-12">
      <div class="flex flex-col w-full px-2">
        <img src="/assets/images/pc.png" alt="" >
      </div>
      <div class="flex flex-col w-full px-4 md:px-0 gap-4 justify-center">
        <div
          class="flex flex-row text-3xl md:text-[38px] text-[#304168] font-medium"
        >
          Torne-se um vendedor no VemQdá
        </div>
        <div class="flex flex-row text-[#304168FC] text-pretty text-[24px]">
          É muito simples! Basta se cadastrar no site do VemQdá e compartilhar
          as ofertas disponíveis em suas redes sociais ou como preferir. Você
          será comissionado por suas vendas!
        </div>
        <div class="flex flex-row items-center justify-center md:justify-start">
          <NuxtLink to="/vemqda/cadastro">
            <UButton label="Quero ser um afiliado" size="xl" />
          </NuxtLink>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col md:flex-row gap-12 w-full justify-between bg-gradient-to-r from-[#F9FFF9] to-white-900 bg-blend-luminosity"
    >
      <div class="flex flex-col w-full gap-8 justify-center max-w-[569px] px-2">
        <div
          class="flex flex-row text-[#304168] text-3xl md:text-[32px] font-medium"
        >
          Possui um ou mais pontos de venda?
        </div>
        <div class="flex flex-row text-lg md:text-[24px]">
          Maximize seus ganhos e conquiste mais clientes com produtos de grandes
          e conceituadas marcas do mercado.
        </div>
        <NuxtLink to="/vemqda/cadastro">
          <div
            class="flex flex-row text-primary justify-center md:justify-start font-bold text-[20px] underline cursor-pointer"
          >
            Quero aumentar meus ganhos
          </div>
        </NuxtLink>
      </div>
      <div
        class="hidden md:flex flex-col w-full blur-[1px] max-w-[735px] justify-end items-end bg-[url('/assets/images/graphs.png')] h-[385px] -z-10 md:object-cover"
      />
    </div>

    <div
      id="ganhos"
      class="flex flex-col md:flex-row py-24 w-full gap-12 px-2 md:px-0"
    >
      <div class="flex flex-col w-full">
        <img src="/assets/images/transfers.png" alt="" >
      </div>
      <div class="flex flex-col w-full gap-4 justify-center">
        <div
          class="flex flex-row text-3xl md:text-[38px] text-[#304168] font-medium"
        >
          Pagamento Otimizado
        </div>
        <div class="flex flex-row text-[#304168FC] text-lg md:text-[24px]">
          Prático, funcional e seguro! Acompanhe seu comissionamento de forma
          simples e transparente. O pagamento é um depósito direto na sua conta!
        </div>
        <div class="flex flex-row justify-center md:justify-start">
          <NuxtLink to="/vemqda/cadastro">
            <UButton label="Quero ser um afiliado" size="xl" />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
