<template>
  <div>
    <LandingPageVemqdaNavbar />
    <LandingPageVemqdaHero />
    <LandingPageVemqdaPartners />
    <LandingPageVemqdaForm />
    <LandingPageVemqdaAdvantages />
    <LandingPageVemqdaFooter />
  </div>
</template>

<script lang="ts" setup></script>

<style>
* {
  scroll-behavior: smooth;
}
</style>
