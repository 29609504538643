<template>
  <div>
    <UModal
      :model-value="isModalOpen"
      prevent-close
      @update:model-value="$emit('close')"
    >
      <UCard>
        <DevOnly />
        <template #header>
          <div class="flex flex-row justify-between items-center">
            <h1>Atribuição</h1>
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              class="-my-1"
              @click="isRetailChainAndPosSelect ? $emit('close') : () => {}"
            />
          </div>
        </template>

        <div class="flex flex-col gap-2">
          <RetailChainSelect :form="form" />
          <PosSelect :form="form" />
        </div>
        <template #footer>
          <div class="flex flex-col md:flex-row gap-2">
            <UButton
              class="w-full md:w-1/2"
              label="Cancelar"
              size="xl"
              variant="outline"
              @click="$emit('close')"
            />
            <UButton
              class="w-full md:w-1/2 disabled:cursor-not-allowed"
              label="Continuar"
              size="xl"
              :disabled="
                Object.keys(form.retail_chain).length === 0 ||
                Object.keys(form.pos).length === 0
              "
              @click="handleConfirm"
            />
          </div>
        </template>
      </UCard>
    </UModal>
  </div>
</template>

<script lang="ts" setup>
import { isAxiosError } from "axios";

defineProps({
  isModalOpen: {
    type: Boolean,
    default: false,
  },
  onClose: {
    type: Function,
    default: () => {},
  },
});
const emit = defineEmits(["close"]);
const { setValues, isRetailChainAndPosSelect, setCanShowChat } =
  useActiveRetailChainAndPosStore();

const { getInitialChats } = useWhatsappChatV1();
const form = ref({
  retail_chain: {},
  pos: "",
});
const toast = useToast();
const axiosV2 = useAxios({ useV2: true });
function handleConfirm() {
  if (Object.keys(form.value.retail_chain) && Object.keys(form.value.pos)) {
    setValues(form.value.retail_chain, form.value.pos);
    getQRCode();
    emit("close");
    getInitialChats();

    return toast.add({
      title: "Rede e PDV selecionados com sucesso",
    });
  }

  toast.add({
    title: "Preencha todos os campos",
    color: "red",
  });
}

const getQRCode = async () => {
  const pos = form.value.pos;

  try {
    await axiosV2.get(`whatsapp-chat/utils/qr-code?pos=${pos?.uuid}`, {
      responseType: "blob",
    });
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 400 || error.response?.status === 403) {
        setCanShowChat(false);
      }
    }
  }
};
</script>

<style></style>
