<template>
  <div>
    <div v-if="isVemqda">
      <LandingPageVemqda />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { isVemqda, theme } = useTheme();

onMounted(() => {
  if (!isVemqda.value) return navigateTo(`/${theme.value}/login`);
});
</script>
