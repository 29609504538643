import axios, { type AxiosInstance } from "axios";
import type { HttpClient } from "./HttpClient";

export class AxiosHttpClient implements HttpClient {
  axiosInstance: AxiosInstance = axios.create();

  constructor(
    readonly apiKey: string = "",
    readonly headers = {},
    baseUrl?: string,
  ) {
    if (baseUrl) {
      this.axiosInstance.defaults.baseURL = baseUrl;
    }
    this.axiosInstance.defaults.headers.common["X-Api-Key"] = this.apiKey;
  }

  async get(url: string): Promise<any> {
    this.axiosInstance.defaults.headers.common["X-Api-Key"] = this.apiKey;
    return this.axiosInstance.get(url);
  }

  async post(url: string, body: any, headers = {}): Promise<any> {
    this.axiosInstance.defaults.headers.common["X-Api-Key"] = this.apiKey;
    const response = await this.axiosInstance.post(url, body, {
      headers: {
        ...this.headers,
        ...headers,
      },
    });
    return response;
  }

  async put(_: string, __: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async delete(_: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  get instance() {
    return this.axiosInstance;
  }
}
