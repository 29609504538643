import type { HttpClient } from "~/infra/http/HttpClient";

export class DoRecaptchaUsecase {
  constructor(private httpClient: HttpClient) {}

  async execute(token: string, xapiKey: string) {
    const runtime = useRuntimeConfig();

    try {
      const response = await this.httpClient.post(
        `${runtime.public.VUE_APP_URL_API}/recaptcha`,
        {
          recaptcha: {
            token,
          },
        },
        {
          "X-Api-Key": xapiKey,
        },
      );

      return response.data;
    } catch (error: unknown) {
      throw new Error(`Erro ao executar recaptcha - ${error}`);
    }
  }
}
