import { default as indexJY7LHQ7k1LMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/alteracao-de-senha/index.vue?macro=true";
import { default as index5SN65A5PSrMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/cadastro/index.vue?macro=true";
import { default as _91uuid_936sRM5K67AJMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/confirmacao-de-email/[uuid].vue?macro=true";
import { default as _91id_93zapIvJynt6Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/configurar-transporte/[id].vue?macro=true";
import { default as _91id_9324NISsQWlQMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/editar-venda/[id].vue?macro=true";
import { default as _91slug_93PxO3uPZXAWMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/em-transito/[slug].vue?macro=true";
import { default as index9nrI75YbdfMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/[uuid]/index.vue?macro=true";
import { default as indexjbvoyTVNKhMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/index.vue?macro=true";
import { default as index1rRV5EFfqvMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/novo/index.vue?macro=true";
import { default as indexGFIrMvaFh2Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/[uuid]/index.vue?macro=true";
import { default as indexC3N1KPGfbjMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/index.vue?macro=true";
import { default as indexuCxcKM2BhMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/novo/index.vue?macro=true";
import { default as index1z6BJc1X6GMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/index.vue?macro=true";
import { default as index1tFrtIiJn9Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/novo/index.vue?macro=true";
import { default as indexTsxZiQKFm5Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/[uuid]/index.vue?macro=true";
import { default as indexsXIpK2UhDKMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/index.vue?macro=true";
import { default as indexPBbgQqRJieMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/novo/index.vue?macro=true";
import { default as indexGuouq6rLinMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/[uuid]/index.vue?macro=true";
import { default as index4cpBE9wccYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/index.vue?macro=true";
import { default as indexwe8OHX9GMpMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/novo/index.vue?macro=true";
import { default as indexvR2JXlAHu6Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/[uuid]/index.vue?macro=true";
import { default as indexoNtO6HNjGWMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/index.vue?macro=true";
import { default as indexOjdjG6mK7XMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/novo/index.vue?macro=true";
import { default as indexRMVNmqgYp9Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/importar-mailing/index.vue?macro=true";
import { default as indexNkqvAUeYWRMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/index.vue?macro=true";
import { default as indexMuesUJJWfsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/[id]/index.vue?macro=true";
import { default as indexqHGpx5FcNCMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/index.vue?macro=true";
import { default as indexooFak7jsaYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/novo/index.vue?macro=true";
import { default as indexkNfIvf5D7zMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/[uuid]/index.vue?macro=true";
import { default as indexh6NCkYxnJUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/index.vue?macro=true";
import { default as indexrtsQT8Jm2FMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/novo/index.vue?macro=true";
import { default as indexKizRJZpobPMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/[uuid]/index.vue?macro=true";
import { default as indexsOLuqmrDegMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/index.vue?macro=true";
import { default as indexuayeR68o2RMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/novo/index.vue?macro=true";
import { default as indexwFOZTLbOnfMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/[id]/index.vue?macro=true";
import { default as index5aMVWHXtbTMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/index.vue?macro=true";
import { default as indexnjCkbXsXOAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/novo/index.vue?macro=true";
import { default as indexqSZzUjaZEKMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/[id]/index.vue?macro=true";
import { default as indexDei209JgICMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/index.vue?macro=true";
import { default as indexT3Xuqk0NknMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/novo/index.vue?macro=true";
import { default as indexYdDjVkiTwYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/[uuid]/index.vue?macro=true";
import { default as indexPkjq5y4e5IMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/index.vue?macro=true";
import { default as indexlHR5BcaFbMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/novo/index.vue?macro=true";
import { default as indexWyapa1ISxqMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/[uuid]/index.vue?macro=true";
import { default as indexAXl0TaBHZBMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/index.vue?macro=true";
import { default as indexMtPEv8XrwfMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/novo/index.vue?macro=true";
import { default as indexw9MYd3KAjpMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/campanha-individual/index.vue?macro=true";
import { default as CardViewhsSS2GLVLaMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/CardView.vue?macro=true";
import { default as indexWsb3ZPhAeuMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/detalhes-da-campanha/[id]/index.vue?macro=true";
import { default as Filtera1AHSz7mMGMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/Filter.vue?macro=true";
import { default as indexLzmWpD39iCMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/index.vue?macro=true";
import { default as ListViewNz1TNA8D9eMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/ListView.vue?macro=true";
import { default as indexxuNSLRnid0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/meus-compartilhamentos/index.vue?macro=true";
import { default as indexCLCVPbaZc9Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/ajuda-login/index.vue?macro=true";
import { default as index6MBG0ZqjSGMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/faq/index.vue?macro=true";
import { default as indexJgFSM99quYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/index.vue?macro=true";
import { default as indexdP3Vweksi1Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/pagamentos/index.vue?macro=true";
import { default as indexgIHgq8H2oRMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/tutorial-uso-vemqda/index.vue?macro=true";
import { default as indexn8PT47dRHzMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/chat/index.vue?macro=true";
import { default as indexbPaWYrCRiKMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/consultar-produtos/index.vue?macro=true";
import { default as indexyGgtYejGcwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/auditoria-de-venda/index.vue?macro=true";
import { default as index8t0qT1gkGCMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/index.vue?macro=true";
import { default as seleciona_45provedorCLtcU4B7EeMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/seleciona-provedor.vue?macro=true";
import { default as indexMKPsKPT1Z6Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/historico-de-vendas/index.vue?macro=true";
import { default as indexSMPdvqBAnxMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/index.vue?macro=true";
import { default as indexU8CbbDtlMKMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/leads/index.vue?macro=true";
import { default as indexf4pfIwR6hpMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/meu-extrato/index.vue?macro=true";
import { default as CardViewtH3my3LY3QMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/CardView.vue?macro=true";
import { default as indexlHJVFqhnCKMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/detalhes-da-oferta/[id]/index.vue?macro=true";
import { default as FilterXAxHhxMO1oMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/Filter.vue?macro=true";
import { default as index9Q91e6fJzaMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/index.vue?macro=true";
import { default as ListViewYcGdRo1yi5Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/ListView.vue?macro=true";
import { default as indexKseLEgVwixMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/meus-compartilhamentos/index.vue?macro=true";
import { default as indexIGmP02YgcTMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/planos/index.vue?macro=true";
import { default as indexlD9qcrHBA9Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/extrato-de-comissionamento/index.vue?macro=true";
import { default as indexh4MERTuOY5Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/index.vue?macro=true";
import { default as indext1KOZsXmemMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-contingencia/index.vue?macro=true";
import { default as indexq7CVhUNdX4Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-credito/index.vue?macro=true";
import { default as indexRn3jkECU5sMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-historico-de-status-substatus/index.vue?macro=true";
import { default as indexkjOXNwQDHfMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-mailings-de-ofertas/index.vue?macro=true";
import { default as indexvDsz0ifxNTMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-ultimo-acesso/index.vue?macro=true";
import { default as indexnbAzx1jANfMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/suporte/index.vue?macro=true";
import { default as edicao_45de_45bancoIQkTmU92ktMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/usuario/edicao-de-banco.vue?macro=true";
import { default as aguardando_45confirmacao_45cliente1txWn5D1CYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente.vue?macro=true";
import { default as consultaVpY0LjckgOMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/consulta.vue?macro=true";
import { default as dados_45do_45clienteAVnp7eTbBcMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/dados-do-cliente.vue?macro=true";
import { default as informacoes_45do_45planoaG2LhayDcLMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/informacoes-do-plano.vue?macro=true";
import { default as retirada_45confirmadaZZ9gIfpyGJMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/retirada-confirmada.vue?macro=true";
import { default as index5gZroV07pQMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/analise-de-credito/index.vue?macro=true";
import { default as indexD6uvsPtMA5Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexfu9dDFOPz9Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexhErQW7FUzvMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/dados-do-cliente/index.vue?macro=true";
import { default as index7sTDYrleStMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexQAoUMxY9SmMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/planos/index.vue?macro=true";
import { default as indexJvIJY4XoZcMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/edicao-de-venda/[id]/index.vue?macro=true";
import { default as index8YOkfkyzlYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-categoria/index.vue?macro=true";
import { default as indexeoLJAXXK9pMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-operadora/index.vue?macro=true";
import { default as index456qN1kXdAMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-do-tipo-de-venda/index.vue?macro=true";
import { default as index3Ab67cI5tvMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/analise-de-credito/index.vue?macro=true";
import { default as indexhMqzc3kpILMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexSu7ktDu7rIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexhbWlSfhLnqMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexpeuaHe0HqRMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexSNE7uzHsqWMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/planos/index.vue?macro=true";
import { default as indexUM5Q3i9ZlTMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/analise-de-credito/index.vue?macro=true";
import { default as indexTpcpPldjgSMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexJB0n0E53PaMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexuD6hevDipsMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexa6P4QQQn4EMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexMbKauEd1fCMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/planos/index.vue?macro=true";
import { default as index0RouudKgANMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/verificacao-usuario/index.vue?macro=true";
import { default as index4XqGHsS3QGMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura/index.vue?macro=true";
import { default as index2df7Gjj4PPMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-credito/index.vue?macro=true";
import { default as indexSDjKpBwgIbMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexHuhXyAcIxKMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexmZsOlVuFBFMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexBUEzkLNsQvMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/planos/index.vue?macro=true";
import { default as indexDooOIrS5a0Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warnYeoZVxTf8FMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migrationJ559kSWZrBMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45lineDkix5GwFDPMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityWj9hCg4aM7Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexCOvwbwLbwZMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/analise-de-credito/index.vue?macro=true";
import { default as index3FZ9a7g05zMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexcIRug7K3QlMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexHcTu2KFfyUMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexgDFxGVLYPqMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/planos/index.vue?macro=true";
import { default as indexe883RwKrs8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warnXiAw6Y6gUbMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migrationNEFO72Lq8GMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45lineME5zlGrNUmMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityEH5CecSwy9Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexrapOAfnimgMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito/index.vue?macro=true";
import { default as indexdjYYdWSPvMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexwckb9TVOCjMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente/index.vue?macro=true";
import { default as index9CtLxi5Yf8Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexCkT8tkFxCwMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/planos/index.vue?macro=true";
import { default as indexY1ZwXgd7R9Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/index.vue?macro=true";
import { default as line_45activation_45warnqUNXfG4QitMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn.vue?macro=true";
import { default as migrationrv5izjGGcBMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration.vue?macro=true";
import { default as new_45linebhGJkvidxTMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line.vue?macro=true";
import { default as portabilityn1ik8am91nMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability.vue?macro=true";
import { default as indexMMt8kf3Z8kMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/analise-de-credito/index.vue?macro=true";
import { default as indexkimusJZwpJMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexkGR7szjYO1Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexhyT50LV5hWMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexnrILB8suHmMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as index5PfYuG7M8sMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/planos/index.vue?macro=true";
import { default as indexq6ShKlGJHuMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/analise-de-credito/index.vue?macro=true";
import { default as indexOLypDeHvHCMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido/index.vue?macro=true";
import { default as indexomJCKciRiMMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda/index.vue?macro=true";
import { default as indexiFWUTgTBptMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/dados-do-cliente/index.vue?macro=true";
import { default as indexAESTxKaBH2Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento/index.vue?macro=true";
import { default as indexaaKVN8TjMxMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/planos/index.vue?macro=true";
import { default as indexCUWjw1n1P5Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/faq/index.vue?macro=true";
import { default as index2wrYvGR6JmMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/index.vue?macro=true";
import { default as index_46clientrZ0zZVBP3WMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/login/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.17.0_jiti@2.4.2__ioredis@5._e463ztcmttstdqewrxualevdcm/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as index6qRpx8QhaFMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/recuperacao-de-senha/index.vue?macro=true";
import { default as aceite_45de_45vendaJeyx5xz8sdMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/alugacell/aceite-de-venda.vue?macro=true";
import { default as indexOqt1fYuwkIMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/aceite-de-venda/index.vue?macro=true";
import { default as indexioGlLLH50QMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/venda-aceita/index.vue?macro=true";
import { default as indexpFGN2sYcu7Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/index.vue?macro=true";
import { default as indexEq42PgdFnBMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/[pdv]/index.vue?macro=true";
import { default as indexzGNpRWgckaMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/index.vue?macro=true";
import { default as indexhQNqy4RkUVMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/aceite-de-venda/index.vue?macro=true";
import { default as indexe9cDeSNbCYMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/venda-aceita/index.vue?macro=true";
import { default as indexHPjm7VT5zRMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/aceite-de-venda/index.vue?macro=true";
import { default as indexMp8rvYCrikMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/venda-aceita/index.vue?macro=true";
import { default as indexsdP0EDZTJuMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/[pdv]/index.vue?macro=true";
import { default as indexWpm1hcHfgGMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/index.vue?macro=true";
import { default as indextnmSylf6bnMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/agendamento/index.vue?macro=true";
import { default as index5VBjV4v7vxMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-de-venda/index.vue?macro=true";
import { default as indexambRNtSfqOMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-finalizado/index.vue?macro=true";
import { default as indexkqJZyLQazeMeta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-recusado/index.vue?macro=true";
import { default as indexc5MwOFjTM2Meta } from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/venda-aceita/index.vue?macro=true";
export default [
  {
    name: "theme-alteracao-de-senha",
    path: "/:theme()/alteracao-de-senha",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/alteracao-de-senha/index.vue")
  },
  {
    name: "theme-cadastro",
    path: "/:theme()/cadastro",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/cadastro/index.vue")
  },
  {
    name: "theme-confirmacao-de-email-uuid",
    path: "/:theme()/confirmacao-de-email/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/confirmacao-de-email/[uuid].vue")
  },
  {
    name: "theme-dashboard-alugacell-configurar-transporte-id",
    path: "/:theme()/dashboard/alugacell/configurar-transporte/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/configurar-transporte/[id].vue")
  },
  {
    name: "theme-dashboard-alugacell-editar-venda-id",
    path: "/:theme()/dashboard/alugacell/editar-venda/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/editar-venda/[id].vue")
  },
  {
    name: "theme-dashboard-alugacell-em-transito-slug",
    path: "/:theme()/dashboard/alugacell/em-transito/:slug()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/alugacell/em-transito/[slug].vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell-uuid",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-aparelhos-alugacell-novo",
    path: "/:theme()/dashboard/cadastros/aparelhos-alugacell/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/aparelhos-alugacell/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens-uuid",
    path: "/:theme()/dashboard/cadastros/armazens/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens",
    path: "/:theme()/dashboard/cadastros/armazens",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-armazens-novo",
    path: "/:theme()/dashboard/cadastros/armazens/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/armazens/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-campanhas",
    path: "/:theme()/dashboard/cadastros/campanhas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-campanhas-novo",
    path: "/:theme()/dashboard/cadastros/campanhas/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/campanhas/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos-uuid",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-categorias-de-servicos-novo",
    path: "/:theme()/dashboard/cadastros/categorias-de-servicos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/categorias-de-servicos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos-uuid",
    path: "/:theme()/dashboard/cadastros/dispositivos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos",
    path: "/:theme()/dashboard/cadastros/dispositivos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-dispositivos-novo",
    path: "/:theme()/dashboard/cadastros/dispositivos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/dispositivos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques-uuid",
    path: "/:theme()/dashboard/cadastros/estoques/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques",
    path: "/:theme()/dashboard/cadastros/estoques",
    meta: indexoNtO6HNjGWMeta || {},
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-estoques-novo",
    path: "/:theme()/dashboard/cadastros/estoques/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/estoques/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-importar-mailing",
    path: "/:theme()/dashboard/cadastros/importar-mailing",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/importar-mailing/index.vue")
  },
  {
    name: "theme-dashboard-cadastros",
    path: "/:theme()/dashboard/cadastros",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas-id",
    path: "/:theme()/dashboard/cadastros/ofertas/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas",
    path: "/:theme()/dashboard/cadastros/ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-ofertas-novo",
    path: "/:theme()/dashboard/cadastros/ofertas/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/ofertas/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs-uuid",
    path: "/:theme()/dashboard/cadastros/pdvs/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs",
    path: "/:theme()/dashboard/cadastros/pdvs",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-pdvs-novo",
    path: "/:theme()/dashboard/cadastros/pdvs/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/pdvs/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-uuid",
    path: "/:theme()/dashboard/cadastros/planos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos",
    path: "/:theme()/dashboard/cadastros/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-planos-novo",
    path: "/:theme()/dashboard/cadastros/planos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/planos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores-id",
    path: "/:theme()/dashboard/cadastros/provedores/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores",
    path: "/:theme()/dashboard/cadastros/provedores",
    meta: index5aMVWHXtbTMeta || {},
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-provedores-novo",
    path: "/:theme()/dashboard/cadastros/provedores/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/provedores/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes-id",
    path: "/:theme()/dashboard/cadastros/redes/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/[id]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes",
    path: "/:theme()/dashboard/cadastros/redes",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-redes-novo",
    path: "/:theme()/dashboard/cadastros/redes/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/redes/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos-uuid",
    path: "/:theme()/dashboard/cadastros/servicos-ativos/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos",
    path: "/:theme()/dashboard/cadastros/servicos-ativos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-servicos-ativos-novo",
    path: "/:theme()/dashboard/cadastros/servicos-ativos/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/servicos-ativos/novo/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios-uuid",
    path: "/:theme()/dashboard/cadastros/usuarios/:uuid()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/[uuid]/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios",
    path: "/:theme()/dashboard/cadastros/usuarios",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/index.vue")
  },
  {
    name: "theme-dashboard-cadastros-usuarios-novo",
    path: "/:theme()/dashboard/cadastros/usuarios/novo",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/cadastros/usuarios/novo/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-campanha-individual",
    path: "/:theme()/dashboard/campanhas/campanha-individual",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/campanha-individual/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-CardView",
    path: "/:theme()/dashboard/campanhas/CardView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/CardView.vue")
  },
  {
    name: "theme-dashboard-campanhas-detalhes-da-campanha-id",
    path: "/:theme()/dashboard/campanhas/detalhes-da-campanha/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/detalhes-da-campanha/[id]/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-Filter",
    path: "/:theme()/dashboard/campanhas/Filter",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/Filter.vue")
  },
  {
    name: "theme-dashboard-campanhas",
    path: "/:theme()/dashboard/campanhas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/index.vue")
  },
  {
    name: "theme-dashboard-campanhas-ListView",
    path: "/:theme()/dashboard/campanhas/ListView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/ListView.vue")
  },
  {
    name: "theme-dashboard-campanhas-meus-compartilhamentos",
    path: "/:theme()/dashboard/campanhas/meus-compartilhamentos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/campanhas/meus-compartilhamentos/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-ajuda-login",
    path: "/:theme()/dashboard/central-de-ajuda/ajuda-login",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/ajuda-login/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-faq",
    path: "/:theme()/dashboard/central-de-ajuda/faq",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/faq/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda",
    path: "/:theme()/dashboard/central-de-ajuda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-pagamentos",
    path: "/:theme()/dashboard/central-de-ajuda/pagamentos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/pagamentos/index.vue")
  },
  {
    name: "theme-dashboard-central-de-ajuda-tutorial-uso-vemqda",
    path: "/:theme()/dashboard/central-de-ajuda/tutorial-uso-vemqda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/central-de-ajuda/tutorial-uso-vemqda/index.vue")
  },
  {
    name: "theme-dashboard-chat",
    path: "/:theme()/dashboard/chat",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/chat/index.vue")
  },
  {
    name: "theme-dashboard-consultar-produtos",
    path: "/:theme()/dashboard/consultar-produtos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/consultar-produtos/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko-auditoria-de-venda",
    path: "/:theme()/dashboard/gestao-bko/auditoria-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/auditoria-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko",
    path: "/:theme()/dashboard/gestao-bko",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/index.vue")
  },
  {
    name: "theme-dashboard-gestao-bko-seleciona-provedor",
    path: "/:theme()/dashboard/gestao-bko/seleciona-provedor",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/gestao-bko/seleciona-provedor.vue")
  },
  {
    name: "theme-dashboard-historico-de-vendas",
    path: "/:theme()/dashboard/historico-de-vendas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/historico-de-vendas/index.vue")
  },
  {
    name: "theme-dashboard",
    path: "/:theme()/dashboard",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/index.vue")
  },
  {
    name: "theme-dashboard-leads",
    path: "/:theme()/dashboard/leads",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/leads/index.vue")
  },
  {
    name: "theme-dashboard-meu-extrato",
    path: "/:theme()/dashboard/meu-extrato",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/meu-extrato/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-CardView",
    path: "/:theme()/dashboard/ofertas/CardView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/CardView.vue")
  },
  {
    name: "theme-dashboard-ofertas-detalhes-da-oferta-id",
    path: "/:theme()/dashboard/ofertas/detalhes-da-oferta/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/detalhes-da-oferta/[id]/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-Filter",
    path: "/:theme()/dashboard/ofertas/Filter",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/Filter.vue")
  },
  {
    name: "theme-dashboard-ofertas",
    path: "/:theme()/dashboard/ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/index.vue")
  },
  {
    name: "theme-dashboard-ofertas-ListView",
    path: "/:theme()/dashboard/ofertas/ListView",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/ListView.vue")
  },
  {
    name: "theme-dashboard-ofertas-meus-compartilhamentos",
    path: "/:theme()/dashboard/ofertas/meus-compartilhamentos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/ofertas/meus-compartilhamentos/index.vue")
  },
  {
    name: "theme-dashboard-planos",
    path: "/:theme()/dashboard/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/planos/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-extrato-de-comissionamento",
    path: "/:theme()/dashboard/relatorios/extrato-de-comissionamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/extrato-de-comissionamento/index.vue")
  },
  {
    name: "theme-dashboard-relatorios",
    path: "/:theme()/dashboard/relatorios",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-contingencia",
    path: "/:theme()/dashboard/relatorios/relatorio-de-contingencia",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-contingencia/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-credito",
    path: "/:theme()/dashboard/relatorios/relatorio-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-historico-de-status-substatus",
    path: "/:theme()/dashboard/relatorios/relatorio-de-historico-de-status-substatus",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-historico-de-status-substatus/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-mailings-de-ofertas",
    path: "/:theme()/dashboard/relatorios/relatorio-de-mailings-de-ofertas",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-mailings-de-ofertas/index.vue")
  },
  {
    name: "theme-dashboard-relatorios-relatorio-de-ultimo-acesso",
    path: "/:theme()/dashboard/relatorios/relatorio-de-ultimo-acesso",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/relatorios/relatorio-de-ultimo-acesso/index.vue")
  },
  {
    name: "theme-dashboard-suporte",
    path: "/:theme()/dashboard/suporte",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/suporte/index.vue")
  },
  {
    name: "theme-dashboard-usuario-edicao-de-banco",
    path: "/:theme()/dashboard/usuario/edicao-de-banco",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/usuario/edicao-de-banco.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-aguardando-confirmacao-cliente",
    path: "/:theme()/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/aguardando-confirmacao-cliente.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-consulta",
    path: "/:theme()/dashboard/venda/alugacell/v1/consulta",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/consulta.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/alugacell/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/dados-do-cliente.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-informacoes-do-plano",
    path: "/:theme()/dashboard/venda/alugacell/v1/informacoes-do-plano",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/informacoes-do-plano.vue")
  },
  {
    name: "theme-dashboard-venda-alugacell-v1-retirada-confirmada",
    path: "/:theme()/dashboard/venda/alugacell/v1/retirada-confirmada",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/alugacell/v1/retirada-confirmada.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/aparelhos/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/aparelhos/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/aparelhos/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/aparelhos/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-aparelhos-v1-planos",
    path: "/:theme()/dashboard/venda/aparelhos/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/aparelhos/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-edicao-de-venda-id",
    path: "/:theme()/dashboard/venda/edicao-de-venda/:id()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/edicao-de-venda/[id]/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-da-categoria",
    path: "/:theme()/dashboard/venda/escolha-da-categoria",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-categoria/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-da-operadora",
    path: "/:theme()/dashboard/venda/escolha-da-operadora",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-da-operadora/index.vue")
  },
  {
    name: "theme-dashboard-venda-escolha-do-tipo-de-venda",
    path: "/:theme()/dashboard/venda/escolha-do-tipo-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/escolha-do-tipo-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-residencial-v1-planos",
    path: "/:theme()/dashboard/venda/seguros-residencial/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros-residencial/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/seguros/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/seguros/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/seguros/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/seguros/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/seguros/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-planos",
    path: "/:theme()/dashboard/venda/seguros/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-seguros-v1-verificacao-usuario",
    path: "/:theme()/dashboard/venda/seguros/v1/verificacao-usuario",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/seguros/v1/verificacao-usuario/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-analise-de-cobertura",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-cobertura/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-internet-fixa-v1-planos",
    path: "/:theme()/dashboard/venda/terra-internet-fixa/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-internet-fixa/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/terra-movel/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/terra-movel/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/terra-movel/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/terra-movel/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-terra-movel-v1-planos",
    path: "/:theme()/dashboard/venda/terra-movel/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/terra-movel/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-controle-cartao-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-controle-cartao/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-controle-cartao/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-line-activation-warn",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/line-activation-warn.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-migration",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/migration.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-new-line",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/new-line.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-abertura-da-venda-portability",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/abertura-da-venda/portability.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-movel-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-movel/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-movel/v1/planos/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-analise-de-credito",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/analise-de-credito",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/analise-de-credito/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-conclusao-de-pedido",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/conclusao-de-pedido/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-confirmacao-de-venda",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/confirmacao-de-venda/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-dados-do-cliente",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/dados-do-cliente",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/dados-do-cliente/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-personalizacao-de-pagamento",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/personalizacao-de-pagamento/index.vue")
  },
  {
    name: "theme-dashboard-venda-vivo-residencial-v1-planos",
    path: "/:theme()/dashboard/venda/vivo-residencial/v1/planos",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/dashboard/venda/vivo-residencial/v1/planos/index.vue")
  },
  {
    name: "theme-faq",
    path: "/:theme()/faq",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/faq/index.vue")
  },
  {
    name: "theme",
    path: "/:theme()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/index.vue")
  },
  {
    name: "theme-login",
    path: "/:theme()/login",
    component: () => createClientPage(() => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/login/index.client.vue"))
  },
  {
    name: "theme-recuperacao-de-senha",
    path: "/:theme()/recuperacao-de-senha",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/[theme]/recuperacao-de-senha/index.vue")
  },
  {
    name: "alugacell-aceite-de-venda",
    path: "/alugacell/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/alugacell/aceite-de-venda.vue")
  },
  {
    name: "dispositivos-aceite-de-venda",
    path: "/dispositivos/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/aceite-de-venda/index.vue")
  },
  {
    name: "dispositivos-venda-aceita",
    path: "/dispositivos/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/dispositivos/venda-aceita/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/index.vue")
  },
  {
    name: "r-user-offer-pdv",
    path: "/r/:user()/:offer()/:pdv()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/[pdv]/index.vue")
  },
  {
    name: "r-user-offer",
    path: "/r/:user()/:offer()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/r/[user]/[offer]/index.vue")
  },
  {
    name: "seguros-residencial-aceite-de-venda",
    path: "/seguros-residencial/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/aceite-de-venda/index.vue")
  },
  {
    name: "seguros-residencial-venda-aceita",
    path: "/seguros-residencial/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros-residencial/venda-aceita/index.vue")
  },
  {
    name: "seguros-aceite-de-venda",
    path: "/seguros/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/aceite-de-venda/index.vue")
  },
  {
    name: "seguros-venda-aceita",
    path: "/seguros/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/seguros/venda-aceita/index.vue")
  },
  {
    name: "t-promoter-offer-pdv",
    path: "/t/:promoter()/:offer()/:pdv()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/[pdv]/index.vue")
  },
  {
    name: "t-promoter-offer",
    path: "/t/:promoter()/:offer()",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/t/[promoter]/[offer]/index.vue")
  },
  {
    name: "terra-agendamento",
    path: "/terra/agendamento",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/agendamento/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-de-venda",
    path: "/terra/internet-fixa/aceite-de-venda",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-de-venda/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-finalizado",
    path: "/terra/internet-fixa/aceite-finalizado",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-finalizado/index.vue")
  },
  {
    name: "terra-internet-fixa-aceite-recusado",
    path: "/terra/internet-fixa/aceite-recusado",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/aceite-recusado/index.vue")
  },
  {
    name: "terra-internet-fixa-venda-aceita",
    path: "/terra/internet-fixa/venda-aceita",
    component: () => import("/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/pages/terra/internet-fixa/venda-aceita/index.vue")
  }
]