<script setup lang="ts">
const { onClick } = defineProps<{
  content: string;
  icon: string;
  isActive?: boolean;
  route?: string;
  onClick?: () => void;
}>();

const { isOpen } = useSiderbarToggle();

function handleClickIfHas() {
  onClick?.();
}

const classWhenIsNotActive =
  "group flex flex-row md:ml-4 ml-1 gap-4 hover:bg-primary hover:text-white dark:text-white rounded-l-full py-2 md:px-4 px-1 transition-all cursor-pointer text-[#111] items-center";

const classWhenIsActive =
  "flex flex-row md:ml-4 ml-1 gap-4 bg-primary hover:bg-primary rounded-l-full py-2 md:px-4 px-1 transition-all cursor-pointer text-white font-bold items-center";
</script>

<template>
  <NuxtLink :to="route">
    <div
      :class="isActive ? classWhenIsActive : classWhenIsNotActive"
      @click="handleClickIfHas"
    >
      <UIcon :name="icon" class="h-5 w-5" />
      <p v-if="isOpen" class="transition-all group-hover:pl-1 font-medium">
        {{ content }}
      </p>
    </div>
  </NuxtLink>
</template>
