<template>
  <div>
    <img
      src="@/assets/images/cc_afiliados.png"
      alt="Logo Clube Conecta"
      class="w-20 h-20"
    >
  </div>
</template>

<script setup lang="ts"></script>
