<template>
  <div
    class="container mx-auto flex flex-col gap-4 bg-[#DAF1EC] p-8 rounded-3xl drop-shadow-xl my-8"
  >
    <div class="flex flex-row">
      <h1 class="text-[#304169] text-[24px] font-medium">
        Traga seus produtos para o VemQda
      </h1>
    </div>
    <div class="flex flex-row">
      <p>
        Seus produtos e serviços alcançam um maior número de clientes ao lado de
        grandes marcas do mercado. Preencha os campos abaixo e nós entraremos em
        contato.
      </p>
    </div>
    <div class="flex flex-col md:flex-row">
      <UForm
        :state="state"
        :on-submit="onSubmit"
        class="flex flex-col md:flex-row gap-4 justify-between w-full items-start md:items-end pb-4 md:pb-0"
      >
        <div class="flex flex-col md:flex-row w-full gap-8">
          <UFormGroup label="Nome" name="name">
            <UInput
              v-model="state.name"
              size="xl"
              placeholder="Digite aqui seu nome"
            />
          </UFormGroup>
          <UFormGroup label="E-mail" name="email">
            <UInput
              v-model="state.email"
              size="xl"
              placeholder="EX: email@email.com.br"
            />
          </UFormGroup>
          <UFormGroup label="Celular" name="phone" class="max-w-[170px]">
            <UInput
              v-model="state.phone"
              size="xl"
              placeholder="Ex: 00 987654321"
            />
          </UFormGroup>
        </div>
        <div
          class="flex pt-4 md:pt-0 flex-row md:w-[210px] h-10 items-start justify-start md:justify-end"
        >
          <UButton
            size="xl"
            type="submit"
            class="w-full"
            block
            :loading="isLoading"
            :disabled="
              state.name === '' || state.email === '' || state.phone === ''
            "
            >Entrar em contato</UButton
          >
        </div>
      </UForm>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isAxiosError } from "axios";
import { AxiosHttpClient } from "~/infra/http/AxiosHttpClient";
import { DoRecaptchaUsecase } from "~/usecases/RecaptchaUsecase";
const axios = useAxios({ useV2: true });
const state = reactive({
  name: "",
  email: "",
  phone: "",
});
const toast = useToast();
const isLoading = ref(false);
const { xApiKey, refresh } = useXApiKey();

const token = ref("");

async function tokenRecaptcha() {
  try {
    refresh();
    const axiosHttpClient = new AxiosHttpClient(xApiKey.value);
    const usecase = new DoRecaptchaUsecase(axiosHttpClient);
    await usecase.execute(token.value, xApiKey.value);
  } catch (error) {
    console.error(error);
  }
}
const onSubmit = async (data) => {
  try {
    refresh();

    isLoading.value = true;
    await tokenRecaptcha();
    const response = await axios.post("/contact", {
      name: state.name,
      email: state.email,
      phone: state.phone,
    });

    toast.add({
      title: "Sucesso",
      description: "Email enviado com sucesso",
      color: "green",
    });
  } catch (error) {
    console.error(error);

    if (isAxiosError(error)) {
      toast.add({
        color: "red",
        title: "Erro",
        description:
          error.response?.data.message || "Ocorreu um erro inesperado",
      });
    }
  } finally {
    isLoading.value = false;
  }
};
</script>
