import { acceptHMRUpdate, defineStore } from "pinia";

export const useVivoMovelV1Store = defineStore("vivo-movel-v1-store", {
  state: () => ({
    _id: "",
    status: [],
    customer: {
      name: "",
      gender: "",
      parentage: "",
      birth: "",
      phone: "",
      email: "",
      cpf: "",
      fixed_phone: "",
    },
    portability: {
      origin_plan: "",
      origin_provider: "",
    },
    address: {
      postcode: "",
      address: "",
      number: "",
      neighborhood: "",
      add_ons: [],
      state: undefined,
      city: undefined,
    },
    credit_analysis: {},
    plan: {},
    payment: {},
    imei: "",
    msidn: "",
    changeTelephone: false,
    area_code: "",
    state: "",
    iccid: "",
    service: "",
    saleEndPayload: {},
    for_user: {},
  }),
  actions: {
    setPortability(payload: any) {
      this.portability = {
        ...this.portability,
        ...payload,
      };
    },
    setOpeningSalePayload({
      cpf,
      service,
      imei = "",
      iccid = "",
      msidn = "",
      area_code = "",
      state = "",
      changeTelephone = false,
    }: OpeningSalePayload) {
      this.customer = {
        ...this.customer,
        cpf,
      };
      this.imei = imei;
      this.msidn = msidn;
      this.changeTelephone = changeTelephone;
      this.area_code = area_code;
      this.iccid = iccid;
      this.service = service;
      this.state = state;
    },

    setUserDataPayload(customer: any, address: any) {
      this.customer = {
        ...this.customer,
        ...customer,
      };
      this.address = {
        ...this.address,
        ...address,
      };
    },

    resetStore() {
      this.$reset();
    },

    setCreditAnalysis(credit_analysis: any) {
      this.credit_analysis = {
        ...this.credit_analysis,
        ...credit_analysis,
      };
    },
    setPlan(plan: any) {
      this.plan = {
        ...this.plan,
        ...plan,
      };
    },
    setCustomizeInvoicePayload(payload: any) {
      this.payment = {
        ...this.payment,
        ...payload,
      };
    },

    addSaleIdFromDraft(_id: string) {
      this._id = _id;
    },

    addSaleStatusFromDraft(status: any) {
      this.status = status;
    },

    async sendSaleToDraft(step: number, axios: any, status: string = "") {
      return await axios.post("sales/draft", {
        sale: {
          ...this.payloadSale,
          step: step,
          type: "MOBILE",
          status: status ?? this.status,
          _id: this._id,
        },
      });
    },
    async sendCanceledSale(step: number, axios: any, status = "") {
      return await axios.post("sales/cancel", {
        sale: {
          ...this.payloadSale,
          step: step,
          type: "MOBILE",
          status,
          _id: this._id,
        },
      });
    },
    setSaleEndPayload(payload: any) {
      this.saleEndPayload = payload;
    },
    setForUser(payload) {
      this.for_user = {
        ...this.for_user,
        ...payload,
      };
    },
  },
  getters: {
    payloadSale(): any {
      return {
        customer: {
          addresses: [this.address],
          birth: this.customer.birth,
          card: {
            agency: this.customer.card_agency,
            number: this.customer.card_number,
            state: this.customer.card_state,
          },
          cpf: this.customer?.cpf?.replace(/\D/g, "") || "",
          email: this.customer.email,
          gender: this.customer.gender,
          name: this.customer.name,
          parentage: this.customer.parentage,
          phone: this.customer?.phone?.replace(/\D/g, "") || "",
          fixed_phone: this.customer?.fixed_phone?.replace(/\D/g, "") || "",
        },
        address: this.address,
        credit_analysis: this.credit_analysis,
        plan: this.plan,
        payment: this.payment,
        imei: this.imei,
        msidn: this.msidn,
        changeTelephone: this.changeTelephone,
        area_code: this.area_code,
        iccid: this.iccid,
        service: this.service,
        mobile: {
          msidn: this.msidn,
          imei: this.imei,
          iccid: this.iccid,
          provider: {
            name: "Vivo",
            slug: "vivo",
          },
        },
        ...(this.for_user && { for_user: this.for_user }),
      };
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVivoMovelV1Store, import.meta.hot));
}

type OpeningSalePayload = {
  cpf: string;
  service: string;
  imei?: string;
  iccid?: string;
  msidn?: string;
  area_code?: string;
  changeTelephone?: boolean;
  state?: string;
};
