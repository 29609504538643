<template>
  <div
    class="bg-[url('/assets/images/LP.png')] w-full bg-cover relative lg:bg-center lg:bg-no-repeat lg:h-[900px]"
  >
    <div class="container md:h-[800px] m-auto px-2 md:px-12">
      <div class="flex flex-col gap-2">
        <div class="flex flex-row">
          <h1
            class="text-[#304168] max-w-[300px] md:max-w-[862px] text-xl md:text-5xl pt-6 md:pt-24 font-semibold md:font-bold"
          >
            Amplie sua renda vendendo com o vemQda!
          </h1>
        </div>

        <div class="flex flex-row">
          <p
            class="text-[#304168] max-w-[242px] md:max-w-[750px] text-sm md:text-3xl md:pt-8 pt-4"
          >
            No vemQdá,
            <span class="text-[#006351]"
              >você amplia sua renda de forma simples</span
            >
            com ofertas de grandes marcas.
          </p>
        </div>

        <div class="flex flex-col md:max-w-[340px] py-8 md:py-12 gap-4 z-10">
          <div class="flex flex-row max-w-[192px]">
            <NuxtLink to="/vemqda/cadastro"
              ><UButton
                label="Cadastre-se"
                size="sm"
                block
                :ui="{
                  size: {
                    sm: 'w-full md:text-lg text-sm',
                  },
                }"
            /></NuxtLink>
          </div>
          <p class="font-medium text-sm">
            Ja é afiliado VemQdá?
            <NuxtLink to="/vemqda/login" class="text-primary">Entrar</NuxtLink>
          </p>
        </div>
      </div>
      <div class="right-0 absolute md:bottom-50 md:w-[600px] bottom-10">
        <img
          src="/assets/images/poquinho.png"
          class="absolute max-w-[80px] md:hidden lg:flex md:max-w-max bottom-[124px] right-16 md:right-60 md:bottom-80 max-w py-16 md:py-16 lg:py-16"
        >
        <img
          src="/assets/images/Group 4865.png"
          class="md:w-full md:max-w-2xl py-16 md:py-16 lg:py-16"
          alt=""
        >
      </div>
    </div>
  </div>
</template>
