<script setup lang="ts">
const route = useRoute();

const { theme } = useTheme();

const { handleLogout } = useLogout();

const {
  canRenderBkoSidebarItem,
  canRenderSaleSidebarItem,
  canRenderRegistrations,
  canRenderLeadSidebarItem,
  canRenderReports,
  canRenderMyExtract,
  canRenderOfferShares,
  canRenderCampaignShares,
  canRenderSaleHistory,
  canRenderPlanPerCategory,
} = useUserStore();

function handleError(error: unknown) {
  console.error(error);
  handleLogout();
}

function getRouteWithTheme(url: string) {
  return `/${theme.value}${url}`;
}

const { isOpen } = useSiderbarToggle();
</script>

<template>
  <NuxtErrorBoundary @error="handleError">
    <div
      class="flex flex-col h-[90vh] bg-white dark:bg-gray-800"
      :class="{
        'md:w-[258px]': isOpen,
        'md:w-[68px]': !isOpen,
      }"
    >
      <div class="flex flex-1 flex-col w-full">
        <div class="flex-col flex gap-2">
          <VSidebarItem
            :is-active="route.path === getRouteWithTheme('/dashboard')"
            content="Página Inicial"
            icon="i-heroicons-home"
            :route="getRouteWithTheme('/dashboard')"
          />
          <VSidebarItem
            v-if="canRenderSaleSidebarItem"
            :is-active="
              route.path.includes(
                getRouteWithTheme('/dashboard/consultar-produtos'),
              )
            "
            content="Vendas"
            icon="i-heroicons-shopping-cart"
            :route="getRouteWithTheme('/dashboard/consultar-produtos')"
          />
          <VSidebarItem
            v-if="canRenderSaleHistory"
            :is-active="
              route.path.includes(
                getRouteWithTheme('/dashboard/historico-de-vendas'),
              )
            "
            content="Histórico de Vendas"
            icon="i-heroicons-presentation-chart-bar"
            :route="getRouteWithTheme('/dashboard/historico-de-vendas')"
          />

          <VSidebarItem
            v-if="canRenderBkoSidebarItem"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/gestao-bko'))
            "
            content="BackOffice"
            icon="i-heroicons-banknotes"
            :route="
              getRouteWithTheme('/dashboard/gestao-bko/seleciona-provedor')
            "
          />

          <VSidebarItem
            v-if="canRenderLeadSidebarItem"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/leads'))
            "
            content="Leads"
            icon="i-heroicons-bolt"
            :route="getRouteWithTheme('/dashboard/leads')"
          />

          <VSidebarItem
            v-if="canRenderPlanPerCategory"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/planos'))
            "
            content="Planos por Categoria"
            icon="i-heroicons-funnel"
            :route="getRouteWithTheme('/dashboard/planos')"
          />
          <VSidebarItem
            v-if="canRenderOfferShares"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/ofertas'))
            "
            content="Compartilhamento de Oferta"
            icon="i-heroicons-share"
            :route="getRouteWithTheme('/dashboard/ofertas')"
          />
          <VSidebarItem
            v-if="canRenderCampaignShares"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/campanhas'))
            "
            content="Campanhas"
            icon="i-heroicons-cake"
            :route="getRouteWithTheme('/dashboard/campanhas')"
          />
        </div>

        <div class="flex-col flex gap-2">
          <VSidebarItem
            v-if="canRenderMyExtract"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/meu-extrato'))
            "
            content="Meu Extrato"
            icon="i-heroicons-currency-dollar"
            :route="getRouteWithTheme('/dashboard/meu-extrato')"
          />
          <VSidebarItem
            v-if="canRenderReports"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/relatorios'))
            "
            content="Relatórios"
            icon="i-heroicons-chart-pie"
            :route="getRouteWithTheme('/dashboard/relatorios')"
          />
          <VSidebarItem
            v-if="canRenderRegistrations"
            :is-active="
              route.path.includes(getRouteWithTheme('/dashboard/cadastros'))
            "
            content="Cadastros"
            icon="i-heroicons-adjustments-horizontal"
            :route="getRouteWithTheme('/dashboard/cadastros')"
          />
        </div>
      </div>

      <div class="grow" />

      <div class="flex flex-col gap-2">
        <VSidebarItem
          content="Sair"
          icon="i-heroicons-arrow-left-end-on-rectangle"
          @click="handleLogout"
        />
        <span v-if="isOpen" class="hidden md:flex text-xs px-2">
          Todos Direitos Reservados a Vertex
        </span>
        <span v-if="!isOpen" class="text-xs px-2 hidden md:flex">
          {{ "&#169 Vertex" }}
        </span>
      </div>
    </div>
  </NuxtErrorBoundary>
</template>
