import { defineStore } from "pinia";
import type { HttpClient } from "~/infra/http/HttpClient";
import { getServiceTypesByPosCode } from "~/services/service-types/v2/service-types-home";

type ServiceType = {
  uuid: string;
  service_category: ServiceCategory;
  provider: Provider;
  name: string;
};

type ServiceCategory = {
  name: string;
};

type Provider = {
  slug: string;
  logo: string;
};

export const useHomePlanStore = defineStore("home-plans-store", {
  state: () => ({
    service_types: [] as ServiceType[],
    offers: [] as Array<any>,
    isHomePlansLoading: false,
    isCampaingOfferLoading: false,
  }),
  actions: {
    getServiceTypesForHome(axios: HttpClient, getPosCode: string) {
      this.isHomePlansLoading = true;
      getServiceTypesByPosCode(getPosCode, axios)
        .then(({ data }) => {
          this.service_types = data.data.service_types;
        })
        .finally(() => {
          this.isHomePlansLoading = false;
        });
    },

    getCampaingPlansForHome(axios: HttpClient) {
      this.isCampaingOfferLoading = true;

      axios.get("/offers?type=CAMPANHA").then(({ data }) => {
        this.offers = data.data.offers;
        this.isCampaingOfferLoading = false;
      });
    },
  },
});
