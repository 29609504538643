<template>
  <div
    id="vantagem"
    class="container mx-auto flex flex-col text-[#304168] pb-24 md:px-6"
  >
    <div class="flex flex-row justify-center py-12">
      <h1 class="text-[32px] font-bold">Vantagens</h1>
    </div>

    <div
      class="flex flex-col md:flex-row gap-24 text-[#304168] items-center justify-between"
    >
      <div class="flex flex-col gap-4 max-w-[382px]">
        <div class="flex flex-row justify-center">
          <h1 class="font-bold text-[26px]">Portifólio variado</h1>
        </div>
        <div class="flex flex-row">
          <p class="text-center text-[24px]">
            Produtos de diferentes seguimentos, enriquecendo seu portfólio e
            aumentando suas oportunidades de venda.
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-4 max-w-[382px]">
        <div class="flex flex-row justify-center">
          <h1 class="font-bold text-[26px]">Segurança e proteção</h1>
        </div>
        <div class="flex flex-row">
          <p class="text-center text-[24px]">
            Investimos em tecnologia de segurança, para garantir a proteção de
            dados, transações e comissionamentos
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-4 max-w-[382px]">
        <div class="flex flex-row justify-center">
          <h1 class="font-bold text-[26px]">Grandes marcas</h1>
        </div>
        <div class="flex flex-row">
          <p class="text-center text-[24px]">
            Trabalhe com produtos de grandes marcas, agregando maior
            credibilidade ao serviço.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style></style>
