<template>
  <div class="bg-white">
    <nav
      class="container flex flex-row m-auto justify-between py-2 px-4 sm:px-6 md:px-8 lg:px-12"
    >
      <div
        class="flex flex-col max-w-24 md:max-w-36 items-center justify-center md:mr-8"
      >
        <img src="/assets/images/logo-vemqda.png" alt="" >
      </div>

      <div
        class="md:flex flex-row justify-center items-center gap-2 md:gap-12 lg:gap-12 font-medium hidden"
      >
        <div class="flex flex-col">
          <a href="#afiliados" class="cursor-pointer">Torne-se um afiliado</a>
        </div>
        <div class="flex flex-col">
          <a href="#ganhos" class="cursor-pointer"> Maximize seus ganhos </a>
        </div>
        <div class="flex flex-col">
          <a href="#vantagem" class="cursor-pointer"> Vantagem </a>
        </div>
      </div>

      <div class="flex flex-row">
        <div class="flex flex-row justify-center items-center gap-4">
          <div class="flex flex-col">
            <NuxtLink to="/vemqda/cadastro">
              <UButton label="Cadastrar" size="lg" variant="ghost" />
            </NuxtLink>
          </div>
          <div class="flex flex-col">
            <NuxtLink to="/vemqda/login">
              <UButton label="Entrar" size="lg" />
            </NuxtLink>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup></script>

<style></style>
