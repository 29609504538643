import { useQuery } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { nanoid } from "nanoid";
import Pusher from "pusher-js";
import {
  getFullChatById,
  getMessages,
  sendAudio,
  sendImage,
  sendMessage,
} from "~/services/whatsapp";
import { toastApiError } from "~/shared/helpers/toastApiError.helper";

const chats = ref<Array<Partial<Chat>>>([]);

export const useWhatsappChatV1 = defineStore(
  "whatsapp-chat-v1",
  () => {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const activeRetailChainStore = useActiveRetailChainAndPosStore();
    const runtimeConfig = useRuntimeConfig();
    const unreadSet = ref(new Set());
    const { receiveNewMessageFromPusher, messagesWithoutUser } =
      useChatsWithoutUser();

    const socketId = ref("");

    const pusher = new Pusher(runtimeConfig.public.PUSHER_KEY, {
      cluster: "sa1",
      channelAuthorization: {
        transport: "ajax",
        endpoint: runtimeConfig.public.PUSHER_URL,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          "Access-Control-Allow-Origin": "*",
        },
      },
    });

    pusher.connection.bind("connected", () => {
      socketId.value = pusher.connection.socket_id;
    });

    watchEffect(() => {
      const posUUID = activeRetailChainStore.pos?.uuid || null;
      if (!posUUID) return;

      pusher
        .subscribe(`private-pos-whatsapp-message.${posUUID}`)
        .bind("pos-whatsapp-message-receive", (data: PusherMessageReceived) => {
          receiveNewMessageFromPusher(data);
        });
    });

    pusher
      .subscribe(`private-whatsapp-message.${userStore.user.uuid}`)
      .bind("whatsapp-message-receive", (data: PusherMessageReceived) => {
        chats.value.find((c) => c.id === data.chat_id).messages.push(data);

        if (getActiveChat.value.id !== data.chat_id) {
          const chat = chats.value.find((c) => c.id === data.chat_id);

          chat.unread = true;

          unreadSet.value.add(data.chat_id);
          triggerNotification(chat.name, data.message);
        }

        if (getActiveChat.value.id === data.chat_id) {
          getActiveChat.value.messages?.push({
            ...data,
          });
        }
      });

    const isSendingMessage = ref(false);
    const toast = useToast();
    const isWhatsAppModalOpen = ref(false);
    const getActiveChat = ref<Partial<ActiveChat>>({});
    const isConnected = ref<boolean>(false);
    const isConnectionModalOpen = ref<boolean>(false);

    const axiosV2 = useAxios({ useV2: true });

    const salesmanChats = ref([]);
    const salesmanActiveChat = ref({});

    const canUserSendMessage = computed(() => {
      if (getActiveChat.value.user === null) return false;
      return getActiveChat.value.user?.uuid !== userStore.user.uuid;
    });

    async function getInitialChats() {
      if (!activeRetailChainStore.pos) return null;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;
      try {
        axiosV2.defaults.headers.common.Authorization = `Bearer ${authStore.access_token}`;
        const { data } = await getMessages(axiosV2, uuid);
        chats.value = data.data.chats;
        chats.value
          .filter((c) => c.unread)
          .map((c) => {
            if (unreadSet.value.has(c.id)) return;

            triggerNotification(c.name, c.messages[0]?.message);

            return unreadSet.value.add(c.id);
          });

        return data.data.chats;
      } catch (error) {
        console.error(error);
      }
    }

    const findChatInChatsByUUID = (uuid: string) =>
      chats.value.find((c) => c.uuid === uuid);

    const findChatInMessagesWithoutUserByUUID = (uuid: string) =>
      messagesWithoutUser.value.find((c) => c.uuid === uuid);

    async function getFullChat(uuid: string) {
      let chat = findChatInChatsByUUID(uuid);
      const chatWithoutUser = findChatInMessagesWithoutUserByUUID(uuid);

      chat = Object.keys(chat || {}).length ? chat : chatWithoutUser;

      if (!chat) return;

      try {
        unreadSet.value.delete(chat.uuid);
        const { data } = await getFullChatById(axiosV2, chat.id);
        setActiveChat(data.data.chat);

        return data.data.chat;
      } catch (error) {
        console.error(error);

        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      }
    }

    async function getFullChatByChatId(id: string) {
      const chat = chats.value.find((c) => c.id === id);
      try {
        unreadSet.value.delete(chat.id);
        const { data } = await getFullChatById(axiosV2, chat.id);
        setActiveChat(data.data.chat);

        return data.data.chat;
      } catch (error) {
        console.error(error);

        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      }
    }

    async function getChatByLeadPage(id: string) {
      try {
        const { data } = await getFullChatById(axiosV2, id);
        setActiveChat(data.data.chat);
      } catch (error) {
        toastApiError(error);
      }
    }

    function setActiveChat(chat: any) {
      getActiveChat.value = chat;
    }

    async function handleSendMessage(input: string) {
      if (!activeRetailChainStore.pos) return;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;

      try {
        isSendingMessage.value = true;
        if (uuid) {
          const { data } = await sendMessage(axiosV2, {
            message: input,
            phone: getActiveChat.value.phone!,
            pos: uuid,
          });

          getActiveChat.value = data.data.chat;
          chats.value
            .find((c) => c.uuid === getActiveChat.value.uuid)
            ?.messages?.push(
              data.data.chat.messages[data.data.chat.messages.length - 1],
            );
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      } finally {
        isSendingMessage.value = false;
      }
    }

    const isChatHasNewNotifications = computed(() => {
      return chats.value.some((c) => c.unread);
    });

    onMounted(() => {
      chats.value = [];
      getActiveChat.value = {};

      getInitialChats();
    });

    const {
      isRefetching: isWhatsAppGetRefetching,
      isLoading: isWhatsAppGetLoading,
      ...whatsAppInitialLoadQuery
    } = useQuery({
      queryKey: ["whatsapp-first-load"],
      queryFn: async () => {
        if (!activeRetailChainStore.pos?.uuid) return [];
        return await getInitialChats().then((data) => data);
      },
    });

    const { isLoading: isChatLoading } = useQuery({
      queryKey: [
        "whatsapp-get-fullchat",
        getActiveChat.value?.uuid ?? nanoid(),
      ],
      queryFn: async () => {
        if (!getActiveChat.value.uuid) return {};

        const chat = await getFullChat(getActiveChat.value.uuid);

        return chat;
      },
    });

    const triggerNotification = (user: string, content: string) => {
      toast.add({
        title: `Nova Mensagem de ${user}`,
        description: content || "",
      });
    };

    async function getFullChatWhenManager(uuid: string) {
      const chat = salesmanChats.value.find((c) => c.uuid === uuid);
      try {
        const { data } = await getFullChatById(axiosV2, chat?.id);
        setActiveChat(data.data.chat);

        return data.data.chat;
      } catch (error) {
        console.error(error);

        toastApiError(error);
      }
    }

    async function handleSendUpload(input: { image: string; caption: string }) {
      if (!activeRetailChainStore.pos) return;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;

      try {
        isSendingMessage.value = true;
        if (uuid) {
          const { data } = await sendImage(axiosV2, {
            phone: getActiveChat.value.phone!,
            pos: uuid,
            image: input.image,
            caption: input.caption,
          });

          getActiveChat.value = data.data.chat;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      } finally {
        isSendingMessage.value = false;
      }
    }

    async function handleSendAudio(input: { audio: string }) {
      if (!activeRetailChainStore.pos) return;
      const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;

      try {
        isSendingMessage.value = true;
        if (uuid) {
          const { data } = await sendAudio(axiosV2, {
            phone: getActiveChat.value.phone!,
            pos: uuid,
            audio: input,
          });

          getActiveChat.value = data.data.chat;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.add({
            title: "Erro",
            description: error.response?.data?.message,
          });
        }
      } finally {
        isSendingMessage.value = false;
      }
    }

    return {
      isConnectionModalOpen,
      isConnected,
      isSendingMessage,
      isChatLoading,
      getInitialChats,
      chats,
      getActiveChat,
      setActiveChat,
      getFullChat,
      handleSendMessage,
      isChatHasNewNotifications,
      isWhatsAppModalOpen,
      whatsAppInitialLoadQuery,
      isWhatsAppGetLoading,
      isWhatsAppGetRefetching,
      getFullChatByChatId,
      getChatByLeadPage,
      canUserSendMessage,
      salesmanActiveChat,
      salesmanChats,
      getFullChatWhenManager,
      handleSendUpload,
      handleSendAudio,
      triggerNotification,
    };
  },
  {
    persist: {
      storage: persistedState.sessionStorage,
    },
  },
);
export interface ActiveChat {
  id: string;
  uuid: string;
  phone: string;
  pos: Pos;
  name: string;
  user: User;
  unread: boolean;
  messages: Array<Message | null>;
}

export interface Message {
  message: string;
  created_at: Date;
  type: Type;
  received?: boolean;
  unread?: boolean;
  pos?: string;
  phone?: string;
}

export enum Type {
  Text = "text",
}

interface Pos {
  id: number;
  uuid: string;
  code: string;
  name: string;
  city: string;
  state: string;
  channel: string;
}

interface User {
  id: number;
  uuid: string;
  cpf: string;
  name: string;
  email: string;
  role: string;
}

interface Chat {
  id: string;
  uuid: string;
  phone: string;
  pos: Pos;
  name: string;
  user: User;
  unread: boolean;
  messages: Message[];
}
